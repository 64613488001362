import React, { } from "react";
import "../../../../Styling/css/chat.css"
let Messages = (props) => {
    let messages = props.data
    let msg = []
    try {
        messages.forEach(element => {
            if (element.user && element.img) {
                msg.push(
                    <div className="message"><img className="msg_img" src={element.message} alt="" /></div>
                )
            } else if (element.user) {
                msg.push(
                    <div className="message"><p>{element.message}</p></div>
                )
            } else {
                msg.push(
                    <div className="message2"><p>{element.message}</p></div>
                )
            }
        });
    }
    catch (e) {
        console.log(JSON.stringify(e))
    }
    return (
        <div className="Chat_container">
            {msg}
        </div>
    )
}
export default Messages