import React, { useState, useEffect } from "react";
import '../../../Styling/css/artisan.css'
import '../../../Styling/css/dash.css'
import '../../../Styling/css/update.css'
import Modal from 'react-modal';
import { getFirestore, query, collection, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { app, app2 } from "../../../firebaseConfig";
import CategoriesPopup from "./popup/categories";
import JobsPopup from "./popup/jobs";
const db = getFirestore(app)
const db2 = getFirestore(app2)
let Update = () => {
    const [pricing, setPricing] = useState({})
    const [modalIsOpen1, setIsOpen1] = useState(false);
    const [modalIsOpen2, setIsOpen2] = useState(false);
    const [options, setOptions] = useState([])
    const [Jobs, setJobs] = useState([])
    const [updateClick, setUpdatedClick] = useState(false)
    const [updatedValue, setUpdatedValue] = useState({
        price: "",
        description: "",
        name: "",
    })
    const [list, setList] = useState({})
    Modal.setAppElement('#root');
    function openModal1() {
        setIsOpen1(true);
    }
    function closeModal1() {
        setIsOpen1(false);
    } function openModal2() {
        setIsOpen2(true);
    }
    function closeModal2() {
        setIsOpen2(false);
    }
    const selected = async (id) => {
        const q2 = query(collection(db, "jobs"));
        await getDocs(q2).then(doc => {
            let tmp2 = []
            let val = {}
            doc.forEach((doc) => {
                let m = doc.data()
                if (m.category === id) {
                    tmp2.push(doc)
                }
            });
            setUpdatedValue(tmp2[0].data())
            let values = tmp2.map(y => {
                let x = y.data()
                val[y.id] = x
                return <option key={y.id} value={y.id}>{x.name}</option>
            })
            setList(val)
            setJobs(values)
        })
    }
    useEffect(() => {
        const temp = async () => {
            const q = query(collection(db, "categories"));
            await getDocs(q).then(doc => {
                let tmp = []
                doc.forEach((doc) => {
                    let x = doc.data().data
                    tmp.push(<option key={doc.id} value={doc.id}>{x.name}</option>)
                });
                setOptions(tmp)
            })

        };
        temp()
        const ref = doc(db2, "pricing", "1")
        getDoc(ref).then(data => {
            setPricing(data.data())
        })
    }, []);
    const update = async (data) => {
        const ref = doc(db, "jobs", updatedValue.idJ)
        if (data.file == null) {
            updateDoc(ref, {
                name: data.name,
                price: data.price,
                category: data.category,
                description: data.description
            }).then(e => {
                alert("Job Updated")
            }).catch(e => {
                console.log(e)
                alert("An Error Occured")
            })
        } else {
            try {
                const data = new FormData();
                data.append('file', data.file);
                data.append('upload_preset', 'qlb8aqjh');
                const response = await fetch('https://api.cloudinary.com/v1_1/du9aa3izh/image/upload', {
                    method: 'POST',
                    body: data
                });
                const responseData = await response.json();
                await updateDoc(ref, {
                    image: responseData.url,
                    name: data.name,
                    price: data.price,
                    category: data.category,
                    description: data.description
                });
                alert("Job Updated");
            } catch (error) {
                console.log(error)
                alert("An error occured");
            }
        }
    }
    const handleChange = ({ target: { value, name } }) => {
        setPricing(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleUpdate = () => {
        const ref = doc(db2, "pricing", "1")
        updateDoc(ref, pricing).then(el => {
            alert("pricing  updated")
        })
    }
    return (
        <div className="middle_container split">
            <div className="row_two split_containers">
                <p className="header">
                    Updates
                </p>
                <section>
                    <div className="col-10">
                        <p className="header2">Categories</p>
                        <select className="option form-control" onChange={({ target: { value } }) => {
                            selected(value)
                        }}
                            style={{
                                marginLeft: "40px"
                            }}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="col-10">
                        <p className="header2">Jobs</p>
                        <select className="option form-control" onChange={({ target: { value } }) => {
                            setUpdatedValue({
                                ...list[value],
                                idJ: value
                            })
                        }}
                            style={{
                                marginLeft: "40px"
                            }}
                        >
                            {Jobs}
                        </select>
                    </div>
                    <div style={{
                        marginTop: "150px",
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}>
                        <button type="button" className="btn btn-primary btn-update" onClick={() => {
                            setUpdatedClick(true)
                            openModal2()
                        }}
                        >
                            Update Job
                        </button>
                        <button className="btn btn-primary btn-update " onClick={openModal1}>Add Category</button>
                        <button className="btn btn-primary btn-update" onClick={openModal2}>Add Job</button>
                    </div>
                </section>
                <CategoriesPopup
                    modalIsOpen1={modalIsOpen1}
                    closeModal1={closeModal1}
                />
                <JobsPopup
                    options={options}
                    modalIsOpen2={modalIsOpen2}
                    closeModal2={closeModal2}
                    update={updateClick}
                    updateJob={update}
                    props={updatedValue}
                />
            </div>
            <div className="row_two split_containers">
                <p className="header">
                    Subscriptions
                </p>
                <div
                    className="col-10"
                >
                    <p className="header2">Monthly Pricing</p>
                    <input
                        className="form-control"
                        style={{
                            marginLeft: "40px"
                        }}
                        value={pricing.monthly}
                        onChange={handleChange}
                    />
                </div>
                <div
                    className="col-10"

                >
                    <p className="header2">Yearly Pricing</p>
                    <input
                        name="yearly"
                        className="form-control"
                        style={{
                            marginLeft: "40px"
                        }}
                        value={pricing.yearly}
                        onChange={handleChange}
                    />
                </div>
                <div
                    className="col-10"

                >
                    <p className="header2">Daily Pricing</p>
                    <input
                        name="daily"
                        className="form-control"
                        style={{
                            marginLeft: "40px"
                        }}
                        value={pricing.daily}
                        onChange={handleChange}
                    />
                    <button className="btn btn-primary" onClick={handleUpdate}>Update</button>
                </div>
            </div>
        </div>
    )
}

export default Update