import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc, getFirestore, getDocs, doc, updateDoc } from "firebase/firestore";
import { dashboardLogin } from "../../../firebaseConfig";
import { Rings } from "react-loader-spinner";
import Error from "../../../errorHandle";
import Modal from 'react-modal';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styling/css/main.css";
const auth = getAuth(dashboardLogin);
const db = getFirestore(dashboardLogin)
const userCollection = collection(db, "admins")
const color = "#000066";
const Admin = () => {
    Modal.setAppElement('#root');
    const [data, setData] = useState([])
    const [edit, setEdit] = useState({ edit: false, id: "" })
    const [role, setRole] = useState("")
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [content, setcontent] = useState("");
    let [show, setshow] = useState(false);
    const [phone, setPhone] = useState("")
    let [btn, setBtn] = useState(
        <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
            Create
        </button>
    );
    const [modal, setModal] = useState(false);
    let submit = (e) => {
        e.preventDefault();
        setBtn(
            <Rings
                height="80"
                width="80"
                color={color}
                radius="6"
                wrapperStyle={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
            />
        );
        if (edit.edit) {
            let ref = doc(db, "admins", edit.id)
            updateDoc(ref, {
                email: email,
                phone: phone,
                role: role
            }).then(el => {
                setBtn(
                    <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
                        Create
                    </button>
                )
                setEdit({ edit: false, id: "" })
                setshow(true);
                setPhone("")
                setEmail("")
                setPhone("")
                setPassword("")
                setcontent("user Edited");
                setTimeout(() => {
                    setshow(false);
                }, 1000);
                setModal(false)
                window.location.reload()
            }).catch(el => {
                setBtn(
                    <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
                        Create
                    </button>
                )
                setEdit({ edit: false, id: "" })

            })
        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    addDoc(userCollection, {
                        email: email,
                        phone: phone,
                        uID: userCredential.user.uid,
                        role: "admin"
                    })
                    setBtn(
                        <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
                            Create
                        </button>
                    );
                    setEdit({ edit: false, id: "" })
                    setPhone("")
                    setEmail("")
                    setPhone("")
                    setPassword("")
                    setshow(true);
                    setcontent("user Created");
                    setTimeout(() => {
                        setshow(false);
                    }, 1000);
                    setModal(false)
                    window.location.reload()
                })
                .catch((error) => {
                    let errCode = error.code.split("/")[1];
                    setBtn(
                        <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
                            Create
                        </button>
                    );
                    setModal(false)
                    setshow(true);
                    setcontent(errCode);
                    setTimeout(() => {
                        setshow(false);
                    }, 1000);
                });
        }
    };

    useEffect(() => {
        getDocs(userCollection).then(docs => {
            let tmp = []
            docs.forEach(el => {
                if (!el.data().userDeleted) {
                    console.log(el.data())
                    tmp.push({ ...el.data(), docID: el.id })
                }
            })
            setData(tmp)
        })
    }, []);

    return (
        <div className="container d-flex" style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "flex-start"
        }}>
            <Error show={show} content={content} />
            <h3>Admins</h3>
            <button
                className="btn btn-primary"
                style={{ backgroundColor: color }}
                onClick={() => setModal(true)}
            >
                New Admin
            </button>
            <div className="Pagination" style={{
                marginTop: "5px"
            }}>
                <div className="Headers i_row">
                    <p>Email</p>
                    <p>Phone</p>
                    <p>Role</p>
                    <p>Edit</p>
                    <p>Delete</p>
                </div>
                <div className="i_container">
                    {data.map(el => (
                        <div className="i_row">
                            <p>{el.email}</p>
                            <p>{el.phone}</p>
                            <p>{el.role}</p>
                            <button className="btn btn-primary" onClick={() => {
                                setRole(el.role)
                                setPhone(el.phone)
                                setEmail(el.email)
                                setEdit({ edit: true, id: el.docID })
                                setModal(true)
                                setBtn(
                                    <button type="submit" className="btn btn-primary login-button" style={{ backgroundColor: color }}>
                                        Edit
                                    </button>
                                )
                            }}>
                                edit
                            </button>
                            <button className="btn btn-primary" onClick={() => {
                                const c = window.confirm("Are you sure you want to delete user")
                                if (c) {
                                    let ref = doc(db, "admins", el.docID)
                                    updateDoc(ref, {
                                        userDeleted: true
                                    }).then(el => {
                                        window.location.reload()
                                    }).catch(el => {
                                        alert("Error User Cant'be Deleted")
                                    })
                                }
                            }}>
                                Delete
                            </button>
                        </div>
                    ))}

                </div>
            </div>
            <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                contentLabel="Create Admin"
                className="modal-container"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content"
                    style={{ width: "40vw" }}>
                    <h3>Create Admin</h3>
                    <form onSubmit={(e) => submit(e)} className="form-contain"
                        style={{ width: "100%" }}>
                        <div className="mb-3"
                            style={{ width: "100%" }}>
                            <input
                                type="number"
                                className="form-control"
                                id="Phone"
                                aria-describedby="Phone Number"
                                placeholder="Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="mb-3"
                            style={{ width: "100%" }}>
                            <input
                                type="email"
                                className="form-control"
                                id="Email"
                                aria-describedby="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        {edit.edit ? <></> : (<div className="mb-3"
                            style={{ width: "100%" }}>
                            <input
                                type="password"
                                className="form-control"
                                id="Password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </div>)}
                        {edit.edit && (
                            <div className="mb-3"
                                style={{ width: "100%" }}>
                                <input
                                    type="role"
                                    className="form-control"
                                    id="role"
                                    placeholder="Role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        )}
                        {btn}
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default Admin;
