import React, { useEffect } from "react";
import '../Styling/css/dash.css'
import Sidebar from "./Sidebar";
import logo from "../images/icon.png"
import { useNavigate } from "react-router-dom";
import { Outlet, } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { dashboardLogin } from "../firebaseConfig";
const auth = getAuth(dashboardLogin)
let Dashboard = () => {
    let navigate = useNavigate()
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (!user) {
                return navigate('/');
            }
        })
    }, [navigate])
    return (
        <div id="main_container">
            <div className="topnav">
                <div className="top_bar">
                    <img src={logo} alt="logo" className="logo_img" />
                    <span>Craftzone</span>
                </div>
            </div>
            <Sidebar />
            <Outlet />
        </div>
    )
}
export default Dashboard