import React from 'react'
import "./error.css"

const Error = (props) => {
    const content = props.content
    if (props.show) {
        return (
            <div className='Error'>
                {content}
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default Error