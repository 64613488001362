import React, { useState, useEffect } from "react";
import '../../../Styling/css/dash.css'
import '../../../Styling/css/main.css'
import '../../../Styling/css/Bookings.css'
import { collection, query, getFirestore, getDocs, orderBy } from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import Books from "./book";
const db = getFirestore(app);
let Bookings = () => {
    const [orders, setOrders] = useState([])
    const [dispatch, setDispatch] = useState(false)
    let fetchdata = async () => {
        let tmp = []
        const docRef = collection(db, "orders");
        const q = query(docRef, orderBy("created"))
        let data = await getDocs(q)
        data.forEach(async (doc) => {
            const data = doc
            tmp.push(data)
        })
        setOrders(tmp.reverse())
    }

    useEffect(() => {
        fetchdata()
    }, [])
    return (
        <div className="middle_container">
            <div className="m_container">
                <div className="header">
                    <p>Orders</p>
                    <div className="d-flex" style={{ gap: "10px" }}>
                        <button type="submit" className="btn btn-primary dispatch-btn" onClick={() => { setDispatch(false) }}>View Bookings</button>
                        <button type="submit" className="btn btn-primary dispatch-btn" onClick={() => { setDispatch(true) }}>View dispatched orders</button>
                    </div>
                </div>
                <div className="Pagination">
                    {dispatch ? <div className="Headers i_row">
                        <p>Order</p>
                        <p>State</p>
                        <p>Address</p>
                        <p>Phone</p>
                        <p>Artisan Phone</p>
                        <p>Dispatch</p>
                        <p>Chat</p>
                    </div> : <div className="Headers i_row">
                        <p>Order</p>
                        <p>State</p>
                        <p>Dispatch</p>
                        <p>Address</p>
                        <p>Phone</p>
                        <p>Chat</p>
                    </div>}
                    <Books data={orders} dispatch={dispatch} />
                </div>
            </div>
        </div >
    )
}
export default Bookings