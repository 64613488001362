import React, { useState, useEffect } from "react";
import '../../../Styling/css/main.css'
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { dashboardLogin } from "../../../firebaseConfig";
const auth = getAuth(dashboardLogin)
const Account = () => {
    let navigate = useNavigate()
    let [email, setEmail] = useState("")
    let submit = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(async (userCredential) => {
            localStorage.removeItem("user-token")
            navigate("/")
        }).catch(error => {
            // eslint-disable-next-line
            let errCode = error.code.split("/")[1]
            console.log(error)
        })
    }
    useEffect(() => {

    }, [])
    return (
        <div className="container" id="logincontainer">
            <div className="mid-container">
                <h1>Reset Password</h1>
                <form onSubmit={submit} className="form-contain">
                    <div className="mb-3">
                        <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary login-button">Reset</button>
                </form>
            </div>
        </div>
    );
}

export default Account