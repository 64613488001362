import React, { useEffect, useState } from "react";
import { collection, getFirestore, getCountFromServer, orderBy } from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import '../../../Styling/css/Bookings.css'
import '../../../Styling/css/Home.css'
import '../../../Styling/css/dash.css'
import '../../../Styling/css/main.css'
import '../../../Styling/css/Bookings.css'
import { query, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
const db = getFirestore(app);
let Home = () => {
    let [one, setOne] = useState("")
    let [two, setTwo] = useState("")
    let [three, setThree] = useState("")
    let fetchdata = async () => {
        const collectionRef1 = collection(db, "users");
        const collectionRef2 = collection(db, "orders");
        const collectionRef3 = collection(db, "Artisans");
        const snapshot1 = await getCountFromServer(collectionRef1);
        const snapshot2 = await getCountFromServer(collectionRef2);
        const snapshot3 = await getCountFromServer(collectionRef3);
        setOne(snapshot1.data().count)
        setTwo(snapshot2.data().count)
        setThree(snapshot3.data().count)
    }
    useEffect(() => {
        fetchdata()
    }, [])
    return (
        <div className="middle_container Home_Box">
            <div className="top_boxes">
                <div className=" Box Box_one">
                    <i className="bi bi-people-fill"></i>
                    <p>Users</p>
                    <p>{one}</p>
                </div>
                <div className=" Box Box_two">
                    <i className="bi bi-calendar-check-fill"></i>
                    <p>Bookings</p>
                    <p>{two}</p>
                </div>
                <div className=" Box Box_three">
                    <i className="bi bi-hammer"></i>
                    <p>Artisans</p>
                    <p>{three}</p>
                </div>
            </div>

            <Bookings />
        </div>
    )
}
export default Home
let Bookings = () => {
    const [orders, setOrders] = useState([])
    let fetchdata = async () => {
        let tmp = []
        const docRef = collection(db, "orders");
        const q = query(docRef, orderBy("created", "desc"))
        let data = await getDocs(q)
        data.forEach(async (doc) => {
            const data = doc.data()
            if (data.State === "pending") {
                tmp.push(data)
            }
        })
        setOrders(tmp)
    }
    useEffect(() => {
        fetchdata()
    }, [])
    return (
        <div className="home_i_contain">
            <div className="m_container">
                <div className="header"><p>Recent orders</p>
                    <Link to="Bookings" className="btn btn-primary s_button">Orders</Link></div>
                <div className="Pagination">
                    <div className="Headers">
                        <p>Order</p>
                        <p>UserId</p>
                        <p>Status</p>
                        <p>Address</p>
                        <p>Phone</p>
                    </div>
                    <Books data={orders} />
                </div>
            </div>
        </div>
    )
}
let Books = (props) => {
    let x = 0
    let data = props.data
    let el = []
    while (x < 3 && data.length !== 0) {
        const element = data[x]
        if (element.order.item === undefined) {
            el.push(<></>)
        } else if (element === undefined) {
            el.push(<></>)
        }
        else {
            el.push(
                <div className="i_row" key={x}>
                    <p>{element.order.item}</p>
                    <p>{element.userid}</p>


                    <p>{element.State}</p>

                    <p>{element.order.address}</p>

                    <p>{element.order.phone}</p>
                </div>
            )
        }
        x++
    };
    return (
        <div className="i_container home_i_contain">
            {el}
        </div>
    )

}
