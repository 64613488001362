import React, { useState, useEffect } from "react";
import '../Styling/css/main.css'
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { dashboardLogin } from "../firebaseConfig";
import { Rings } from "react-loader-spinner";
import Error from "../errorHandle";
const auth = getAuth(dashboardLogin)
const color = "#000066"
let Login = () => {
    let navigate = useNavigate()
    let [email, setEmail] = useState("")
    let [content, setcontent] = useState("")
    let [show, setshow] = useState(false)
    let [password, setPassword] = useState("")
    let [btn, setBtn] = useState(<button type="submit" className="btn btn-primary login-button">Login</button>)
    let submit = (e) => {
        e.preventDefault();
        setBtn(<Rings
            height="80"
            width="80"
            color={color}
            radius="6"
            wrapperStyle={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
        />)
        signInWithEmailAndPassword(auth, email, password).then(
            async (userCredential) => {
                localStorage.setItem("user-token", userCredential.user.uid)
                navigate("/Dashboard")
            }).catch(error => {
                // eslint-disable-next-line
                let errCode = error.code.split("/")[1]
                setBtn(<button type="submit" className="btn btn-primary login-button">Login</button>)
                setshow(true)
                setcontent(errCode)
                setTimeout(() => {
                    setshow(false)
                }, 1000)
            })
    }
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                navigate('/Dashboard');
            }
        })

    }, [navigate])
    return (
        <div className="container" id="logincontainer">
            <Error content={content} show={show} />
            <div className="mid-container">
                <h1>Login</h1>
                <form onSubmit={submit} className="form-contain">
                    <div className="mb-3">
                        <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    {btn}
                </form>
                <p onClick={() => {
                    let x = prompt("Email Address", email)
                    sendPasswordResetEmail(auth, x).then(x => {
                        console.log(x)
                    })
                }}
                    style={{
                        cursor: "pointer"
                    }}
                >Reset Password</p>
            </div>
        </div>
    );
}

export default Login;