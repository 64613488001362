import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../../Styling/css/Bookings.css"
import '../../../../Styling/css/details.css'
const Details = () => {
    const navigate = useNavigate()
    const state = useLocation().state


    return (
        <div className="details">
            <button className="btn btn-primary login-button s_two" onClick={() => {
                navigate("/Dashboard/Artisan")
            }}>Close</button>
            <p className="label_custom">Address</p>
            <p>{state.Address}</p>
            <p className="label_custom">Name</p>
            <p>{state.Fullname}</p>
            <p className="label_custom">Lga</p>
            <p>{state.Lga}</p>
            <p className="label_custom">Phone Number</p>
            <p>{state.Phone}</p>
            <p className="label_custom">Email</p>
            <p>{state.email}</p>
            <p className="label_custom">Jobs</p>
            <select>
                {[state.Orders.map((el) => (<option key={el.id}>{el.name}</option>))]}
            </select>
        </div>
    )
}

export default Details