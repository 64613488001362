import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getFirestore, collection, where, query, getDocs, doc, updateDoc } from "firebase/firestore";
import '../../../../Styling/css/delegate.css'
import { app, app2 } from "../../../../firebaseConfig";
const db = getFirestore(app2)
const db2 = getFirestore(app)
const Delegate = () => {
    let navigate = useNavigate()
    const state = useLocation().state
    const [data, setData] = useState([])
    let { id } = useParams()
    console.log(state, "state")

    useEffect(() => {
        let delegate = async (artisan, id, order, oID) => {
            const ref = doc(db, "artisans", id)
            const ref2 = doc(db2, "orders", state.id)
            console.log({
                active: true,
                activeJob: { order, oID: oID },
                Orders: [{ order, oID: oID }, ...artisan.Orders],
            })
            updateDoc(ref, {
                active: true,
                activeJob: { order, oID: oID },
                Orders: [{ order, oID: oID }, ...artisan.Orders],
            }).then(x => {
                updateDoc(ref2, {
                    State: "dispatched",
                    artisan: artisan,
                    aID: id
                }).then(x => {
                    navigate("/Dashboard/Bookings")
                })
            })


        }
        let fetchContent = async () => {
            let tmp = []
            let ref = collection(db, "artisans")
            let q = query(ref, where("jobId", "==", id))
            let docs = await getDocs(q)
            docs.forEach(doc => {
                let item = doc.data()
                console.log(item, "artisan")
                // if the artisan and the booking is within the same lga
                if (item.approved) {
                    tmp.push(
                        <div key={item.Uid} className="delegate-row">
                            <div>
                                <p>Name</p>
                                <p>Phone</p>
                                <p>Rating</p>
                                <p>Dispatch</p>
                            </div>
                            <div>
                                <p>{item.Fullname}</p>
                                <p>{item.Phone}</p>
                                <p>{item.rating}</p>
                                <button className="btn btn-primary delegate-btn" onClick={() => {
                                    delegate(item, doc.id, state.order, state.id)
                                }}>Dispatch</button>
                            </div>
                        </div>
                    )
                }
            })
            setData(tmp)
        }
        fetchContent()
    }, [id, state, navigate])
    return (
        <div>
            <div className="Chat">
                <button className="btn btn-primary login-button s_two" onClick={() => {
                    navigate("/Dashboard/Bookings", {})
                }}>Close</button>
                <div className="contents">
                    {data.length ? data : <div className="delegate-center">No artisans registered for this Job is close by</div>}
                </div>
            </div>
        </div>
    )
}

export default Delegate