import React, { useState, useEffect } from 'react';
import {
    getFirestore,
    collection,
    query,
    orderBy,
    where,
    getDocs,
    addDoc,
    serverTimestamp,
    onSnapshot,
} from 'firebase/firestore';
import { app } from '../../../firebaseConfig';

const db = getFirestore(app);
const messagesCollection = collection(db, 'customer-messages');

const CustomerSupport = () => {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState({});
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const q = query(messagesCollection, orderBy('timestamp'));
                const querySnapshot = await getDocs(q);
                const chatIds = new Set();
                const tmp = {};
                querySnapshot.forEach((doc) => {
                    tmp[doc.data().currentChatId] = doc.data();
                    chatIds.add(doc.data().currentChatId);
                });
                setChats(Array.from(chatIds).reverse());
                setUsers(tmp);
            } catch (error) {
                console.error('Error fetching chats:', error);
            }
        };
        fetchChats();
    }, []);

    useEffect(() => {
        const fetchMessages = async () => {
            if (selectedChat) {
                try {
                    const q = query(
                        messagesCollection,
                        where('currentChatId', '==', selectedChat),
                        orderBy('timestamp')
                    );
                    const querySnapshot = await getDocs(q);
                    const messagesData = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }));
                    setMessages(messagesData);
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            }
        };

        const setupMessageListener = () => {
            if (selectedChat) {
                const q = query(
                    messagesCollection,
                    where('currentChatId', '==', selectedChat),
                    orderBy('timestamp')
                );

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const messagesData = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }));
                    setMessages(messagesData);
                });

                return unsubscribe;
            }

            return () => { };
        };

        fetchMessages();
        const unsubscribeMessageListener = setupMessageListener();

        return () => {
            unsubscribeMessageListener();
        };
    }, [selectedChat]);

    const handleChatSelection = (chatId) => {
        setSelectedChat(chatId);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        try {
            await addDoc(messagesCollection, {
                currentChatId: selectedChat,
                text: newMessage,
                timestamp: serverTimestamp(),
                admin: true,
                userData: users[selectedChat]?.userData || {},
                userId: users[selectedChat]?.userId,
            });

            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-4 text-dark p-4">
                    <h2>Users</h2>
                    <ul className="list-group" style={{
                        borderRadius: "0px",
                        height: "78vh",
                        overflow: "scroll"
                    }}>
                        {chats.map((chatId) => (
                            <li
                                key={chatId}
                                className={`list-group-item ${selectedChat === chatId ? 'bg-primary text-light' : ''
                                    }`}
                                onClick={() => handleChatSelection(chatId)}
                            >
                                {users[chatId]?.userData
                                    ? JSON.parse(users[chatId]?.userData).Fullname
                                    : ''}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-8 p-4">
                    <div style={{
                        height: "55vh",
                        overflow: "scroll",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        {messages.map((message) => (
                            <div
                                key={message.id}
                                className={`list-group-item ${message.user ? 'bg-primary text-light' : 'bg-secondary text-light'}`}
                                style={(message.user ? {
                                    alignSelf: "flex-end",
                                    width: "fit-content",
                                    borderRadius: "5px",
                                    marginTop: "15px"
                                } : {
                                    alignSelf: "flex-start",
                                    width: "fit-content",
                                    borderRadius: "5px",
                                    marginTop: "15px"
                                })}
                            >
                                <p style={{ padding: "10px" }}>{message.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button
                            className="btn btn-primary mt-2"
                            onClick={handleSendMessage}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerSupport;
