import React from "react";

let Pagination = (props) => {
    let nPages = props.nPages
    let setCurrentPage = props.setCurrentPage
    let currentPage = props.currentPage
    const nextPage = () => {
        if (currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    return (
        <div>
            <nav >
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} href='#'>
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button onClick={() => { setCurrentPage(pgNumber) }} className='page-link'>
                                {pgNumber}
                            </button>
                        </li>
                    ))}
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}>
                            Next
                        </button>
                    </li>
                </ul>
            </nav>

        </div>
    )
}
export default Pagination