import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyBm5KR_inuZsXXm8hd3gW-KYzSFiA6eqi8",
    authDomain: "craftzone-c97ce.firebaseapp.com",
    projectId: "craftzone-c97ce",
    storageBucket: "craftzone-c97ce.appspot.com",
    messagingSenderId: "329954073292",
    appId: "1:329954073292:web:60e5decb4b9d4480e7c069",
    measurementId: "G-9QNM3D2LM8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const firebaseConfig2 = {
    apiKey: "AIzaSyAyv-IotiBfCXEDNZlcy3nMgVPBmKbL1W4",
    authDomain: "craftzone-artisans.firebaseapp.com",
    projectId: "craftzone-artisans",
    storageBucket: "craftzone-artisans.appspot.com",
    messagingSenderId: "810415276387",
    appId: "1:810415276387:web:2f2d53168e93018dc45356",
    measurementId: "G-XHNR0VD5TF"
};

// Initialize Firebase
export const app2 = initializeApp(firebaseConfig2, "craftzone-artisans");


const firebaseConfig3 = {
    apiKey: "AIzaSyBm5KR_inuZsXXm8hd3gW-KYzSFiA6eqi8",
    authDomain: "craftzone-c97ce.firebaseapp.com",
    projectId: "craftzone-c97ce",
    storageBucket: "craftzone-c97ce.appspot.com",
    messagingSenderId: "329954073292",
    appId: "1:329954073292:web:60e5decb4b9d4480e7c069",
    measurementId: "G-9QNM3D2LM8"
};

// Initialize Firebase
export const app3 = initializeApp(firebaseConfig3, "craftzone");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const dashboardConfig = {
    apiKey: "AIzaSyCrsjzZtiiGzTitZlXoSpHiS2Vmag7FNlA",
    authDomain: "craftzone-dashboard.firebaseapp.com",
    projectId: "craftzone-dashboard",
    storageBucket: "craftzone-dashboard.appspot.com",
    messagingSenderId: "808281271728",
    appId: "1:808281271728:web:128a5c3844c7d96407ba75",
    measurementId: "G-MDJZQ2DZ4T"
};

// Initialize Firebase
export const dashboardLogin = initializeApp(dashboardConfig, "craftzone-dashboard");