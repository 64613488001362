import React, { useState } from "react";
import "../../../../Styling/css/bookings2.css"
import { Outlet } from "react-router-dom";
import Pagination from "./pagination";
import { useNavigate } from "react-router-dom";
let List = (props) => {
    const data = props.data
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const navigate = useNavigate()
    let b_data = []
    let i = 0
    let approve = props.approve
    // display each element by creating and storing each element in an array
    data.forEach(el => {
        let element = el.data()
        if (element.State === undefined || element === undefined || element.Address === undefined || element.Lga === undefined || element.Address === undefined) {
        } else if (props.artisans) {
            b_data.push(
                <div className="i_row" key={i}>
                    <p>{element.Fullname}</p>
                    <p>{element.email}</p>
                    <p>{element.Phone}</p>
                    <button className="btn btn-primary s_button" onClick={() => {
                        props.complete(el.id, element.activeJob.oID)
                    }}>
                        Mark Complete
                    </button>
                </div>
            )
        }
        else if (props.approve) {
            b_data.push(
                <div className="i_row" key={i}>
                    <p>{element.Fullname}</p>
                    <p>{element.email}</p>
                    <p>{element.Phone}</p>
                    <button className="btn btn-primary s_button" onClick={() => {
                        navigate(`${element.Uid}`, { state: element })
                    }}>
                        Details
                    </button>
                    <button className="btn btn-primary s_button" onClick={() => {
                        approve(el.id)
                    }}>
                        Approve
                    </button>
                </div>
            )
        } else if (props.all) {
            b_data.push(
                <div className="i_row" key={i}>
                    <p>{element.Fullname}</p>
                    <p>{element.email}</p>
                    <p>{element.Phone}</p>
                    <button className="btn btn-primary s_button" onClick={() => {
                        navigate(`${element.Uid}`, { state: element })
                    }}>
                        Details
                    </button>
                </div>)
        }
        i++
    });
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = b_data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(b_data.length / recordsPerPage)
    return (
        <div className="i_container" style={{ marginTop: "15px" }}>
            {currentRecords}
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Outlet />
        </div>
    )
}
export default List