import React, { useState, useRef, useEffect } from "react";
import "../../../Styling/css/Bookings.css";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, getFirestore, updateDoc, onSnapshot } from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import Messages from "./Messages/messages";

let db = getFirestore(app);

const Chat = (props) => {
    let navigate = useNavigate();
    let { id } = useParams();
    const [data, setdata] = useState([]);
    const [value, setValue] = useState("");
    let bottom = useRef(null);

    useEffect(() => {
        const docRef = doc(db, "orders", id);

        // Fetch initial data
        const fetchData = async () => {
            try {
                const doc_data = await getDoc(docRef);
                const initialData = doc_data.data();
                setdata(initialData.chat);
                bottom.current?.scrollIntoView({ behavior: 'smooth' });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();

        // Add listener for real-time updates
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            const updatedData = docSnapshot.data()?.chat || [];
            setdata(updatedData);
            bottom.current?.scrollIntoView({ behavior: 'smooth' });
        });

        return () => unsubscribe(); // Cleanup the listener on component unmount
    }, [id]);

    const send = async () => {
        const docRef = doc(db, "orders", id);
        await updateDoc(docRef, {
            chat: [...data, { message: value, user: false, admin: true }],
        });
        setValue("");
    };

    return (
        <div>
            <div className="Chat">
                <button
                    className="btn btn-primary login-button s_two"
                    onClick={() => {
                        navigate("/Dashboard/Bookings", { state: { length: data.length } });
                    }}
                >
                    Close
                </button>
                <Messages data={data} />
                <div ref={bottom}></div>
                <div id="input_container">
                    <input
                        value={value}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="email"
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                    <button className="btn btn-primary s_button" onClick={send}>
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Chat;
