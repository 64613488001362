import React, { useState } from "react";
import "../../../Styling/css/Bookings.css"
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Pagination from "./pagination";
let Books = (props) => {
    const data = props.data
    let location = useLocation()
    console.log(location)
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    let elements = []
    let i = 0
    const navigate = useNavigate()
    let redirect_Chat = (e) => {
        navigate(`Chat/${e}`)
    }
    // display each element by creating and storing each instance in an array
    data.forEach(el => {
        let element = el.data()
        if (props.dispatch) {
            if (element.State === "dispatched") {
                elements.push(
                    <div className="i_row" key={i}>
                        <p>{element.order.item}</p>
                        <p className="i_row_2">{element.State}</p>
                        <p>{element.order.address}</p>
                        <p>{element.order.phone}</p>
                        <p>{element.artisan.Phone}</p>
                        <button className="btn btn-primary s_button" onClick={() => { navigate("Delegate/" + element.order.jId, { state: { order: element, id: el.id } }) }}>Dispatch</button>

                        <div className="Notifications_Chat">
                            <button className="btn btn-primary s_button" onClick={() => { redirect_Chat(el.id) }}>Chat</button>
                            {/* {(Notifications) && <div className="Notifications"></div>} */}
                        </div>
                    </div>)
            }
        } else {
            if (element.State !== "dispatched" && element.State !== "fulfilled") {
                elements.push(
                    <div className="i_row" key={i}>
                        <p>{element.order.item}</p>
                        <p className="i_row_2">{element.State}</p>
                        <button className="btn btn-primary s_button" onClick={() => { navigate("Delegate/" + element.order.jId, { state: { order: element, id: el.id } }) }}>Dispatch</button>
                        <p>{element.order.address}</p>
                        <p>{element.order.phone}</p>
                        <div className="Notifications_Chat">
                            <button className="btn btn-primary s_button" onClick={() => { redirect_Chat(el.id) }}>Chat</button>
                            {/* {(Notifications) && <div className="Notifications"></div>} */}
                        </div>
                    </div>
                )
            }
        }
        i++
    });
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = elements.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(elements.length / recordsPerPage)
    return (
        <div className="i_container">
            {currentRecords}
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Outlet />
        </div>
    )
}
export default Books