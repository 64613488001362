import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";
import '../../../../Styling/css/update.css';

const db = getFirestore(app);

const JobsPopup = ({ modalIsOpen2, closeModal2, options, update, updateJob, props }) => {
    const [name, setName] = useState(props.name || '');
    const [textInput, setTextinput] = useState(props.description || '');
    const [price, setPrice] = useState(props.price || '');
    const [ref, setRef] = useState(props.category || '');
    const [file2, setFile2] = useState(null);
    const handleFileChange2 = (e) => {
        setFile2(e.target.files);
    };
    useEffect(() => {
        // Set state values based on props when props change
        setName(props.name || '');
        setTextinput(props.description || '');
        setPrice(props.price || '');
        setRef(props.category || '');
    }, [props]);
    const handleUploadClick2 = async () => {
        if (update) return updateJob({
            file: file2,
            price: price,
            name: name,
            description: textInput,
            category: ref,
        })
        try {
            const data = new FormData();
            data.append('file', file2[0]);
            data.append('upload_preset', 'qlb8aqjh');
            const response = await fetch('https://api.cloudinary.com/v1_1/du9aa3izh/image/upload', {
                method: 'POST',
                body: data
            });
            const responseData = await response.json();
            const date = new Date();

            await addDoc(collection(db, "jobs"), {
                name: name,
                image: responseData.url,
                description: textInput,
                price: price,
                category: ref,
                created: date.getTime()
            });
            alert("Job Created");
        } catch (error) {
            console.log(error)
            alert("An error occured");
        }
    };
    return (
        <div>
            <Modal
                isOpen={modalIsOpen2}
                onRequestClose={closeModal2}
                className="Modal"
            >
                <h2>Jobs</h2>
                <div>
                    <p style={{
                        margin: "0px",
                        padding: "0px"
                    }}>
                        Image,
                    </p>
                    <input type="file" onChange={handleFileChange2} />
                    <p style={{
                        margin: "0px",
                        padding: "0px"
                    }}
                    >
                        Categories
                    </p>
                    <select
                        onClick={(e) => setRef(e.target.value)}>
                        {[...options]}
                    </select>
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            aria-describedby="Job Name"
                            placeholder="Job Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="text"
                            className="form-control"
                            aria-describedby="Price"
                            placeholder="Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <textarea
                            className="form-control height"
                            value={textInput}
                            onChange={(e) => setTextinput(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <button type="button" style={{ width: "100%" }} className="btn btn-primary s_button" onClick={handleUploadClick2}>
                    Add New
                </button>
            </Modal>
        </div>
    );
};

export default JobsPopup;
