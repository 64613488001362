import React, { useState } from "react";
import Modal from 'react-modal';

import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";
import Error from "../../../../errorHandle";
const db = getFirestore(app)
let CategoriesPopup = (props) => {
    let modalIsOpen1 = props.modalIsOpen1
    let closeModal1 = props.closeModal1
    const [visible, setVisible] = useState({
        visible: false,
        text: ""
    })

    const [file1, setFile1] = useState([]);

    const [name, setName] = useState("");
    const handleFileChange = (e) => {
        setFile1(e.target.files)
    };

    const handleUpload = async () => {
        setVisible({ visible: true, text: "creating category ..." })
        closeModal1(true)
        console.log(<Error />)
        try {
            const data = new FormData();
            // eslint-disable-next-line no-use-before-define
            data.append('file', file1[0]);
            data.append('upload_preset', 'qlb8aqjh');
            await fetch('https://api.cloudinary.com/v1_1/du9aa3izh/image/upload', {
                method: 'POST',
                body: data
            }).then(res => res.json()).then(async res => {
                const date = new Date()
                console.log(res.url)
                await addDoc(collection(db, "categories"), {
                    data: {
                        icon: res.url,
                        name: name,
                        created: date.getTime(),
                    }
                }).then(x => {
                    setVisible({ visible: true, text: "category Created" })
                    setTimeout(() => {
                        setVisible({ visible: false, text: "" })
                    }, 1000)
                })
            })
        } catch (e) {
            let text = JSON.stringify(e)
            alert(text)
            setVisible({ visible: true, text: text })
            setTimeout(() => {
                setVisible({ visible: false, text: "" })
            }, 1000)
        }
    }

    return (
        <div>
            <Error visible={visible.visible} text={visible.text} />
            <Modal
                isOpen={modalIsOpen1}
                onRequestClose={closeModal1}
                className="Modal"
            >
                <h2 style={{
                    marginLeft: "0px"
                }}>
                    Categories
                </h2>
                <div>
                    <p>Upload Icon</p>
                    <input
                        type="file"
                        onChange={handleFileChange}
                    />
                    <div className="mb-3">
                        <input
                            type="email"
                            className="form-control"
                            aria-describedby="Category Name"
                            placeholder="Category Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-primary s_button "
                    onClick={handleUpload}
                    style={{ width: "100%" }}
                >
                    Add new
                </button>
            </Modal>
        </div>
    )
}

export default CategoriesPopup;