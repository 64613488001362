import './App.css';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Bookings from './components/Dashboard/Sub_Pages/Bookings/Bookings.js'
import Chat from './components/Dashboard/Sub_Pages/Bookings/chat';
import Home from './components/Dashboard/Sub_Pages/Home/Home';
import Artisan from './components/Dashboard/Sub_Pages/Artisan/artisan';
import Update from './components/Dashboard/Sub_Pages/Updates/update';
import categoriesPopup from './components/Dashboard/Sub_Pages/Updates/popup/categories';
import bannerPopup from './components/Dashboard/Sub_Pages/Updates/popup/banner';
import jobsPopup from './components/Dashboard/Sub_Pages/Updates/popup/jobs';
import Details from './components/Dashboard/Sub_Pages/Artisan/Lists/details';
import Delegate from './components/Dashboard/Sub_Pages/Bookings/delegate/delegate';
import Account from './components/Dashboard/Sub_Pages/Account/Account';
import Admin from './components/Dashboard/Sub_Pages/Users';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import CustomerSupport from './components/Dashboard/Sub_Pages/CustomerSupport/index.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='Dashboard' element={<Dashboard />} >
            <Route index element={<Home />} />
            <Route path='Bookings' element={<Bookings />} >
              <Route path='Chat/:id' index element={<Chat />} />
              <Route path='Delegate/:id' index element={<Delegate />} />
            </Route>
            <Route path='Artisan' element={<Artisan />} >
              <Route path=':id' element={<Details />} />
            </Route>
            <Route path='Support' element={<CustomerSupport />} >
            </Route>
            <Route path='Update' element={<Update />} >
              <Route path='categories' element={categoriesPopup} />
              <Route path='banner' element={bannerPopup} />
              <Route path='jobs' element={jobsPopup} />
            </Route>
            <Route path='Account' element={<Account />} />
            <Route path='Admin' element={<Admin />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
